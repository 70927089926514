import { useMemo, useState } from 'react'

import { createContext, useContext } from 'use-context-selector'

interface IPaymentMethodContextProviderProps {
  children?: React.ReactNode
}

interface IPaymentMethodContext {
  paymentMethod: TPaymentMethod
  setPaymentMethod: React.Dispatch<React.SetStateAction<TPaymentMethod>>
  paymentMethodsAvailable: TPaymentMethod[]
  setPaymentMethodsAvailable: React.Dispatch<React.SetStateAction<TPaymentMethod[]>>
}

const PaymentMethodContext = createContext<IPaymentMethodContext>({} as IPaymentMethodContext)

export const PaymentMethodContextProvider: React.FC<IPaymentMethodContextProviderProps> = ({ children }) => {
  const [paymentMethod, setPaymentMethod] = useState<TPaymentMethod>(null)
  const [paymentMethodsAvailable, setPaymentMethodsAvailable] = useState<TPaymentMethod[]>(['pix'])

  const contextValues = useMemo(
    () => ({
      paymentMethod,
      setPaymentMethod,
      paymentMethodsAvailable,
      setPaymentMethodsAvailable
    }),
    [paymentMethod, setPaymentMethod, paymentMethodsAvailable, setPaymentMethodsAvailable]
  )

  return <PaymentMethodContext.Provider value={contextValues}>{children}</PaymentMethodContext.Provider>
}

export const usePaymentMethod = () => {
  return useContext(PaymentMethodContext)
}
