import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import type { FetcherWithComponents } from '@remix-run/react'
import { useFetcher } from '@remix-run/react'

import { createContext, useContext } from 'use-context-selector'

import { usePaymentMethod } from '~/contexts/paymentMethod'

interface ICreditCardFormContextProviderProps {
  children?: React.ReactNode
}

type TSubmitStatus = 'success' | 'error' | null

interface ICreditCardFormContext {
  fetcher: FetcherWithComponents<any>
  name: string
  setName: React.Dispatch<React.SetStateAction<string>>
  number: string
  setNumber: React.Dispatch<React.SetStateAction<string>>
  expiration: string
  setExpiration: React.Dispatch<React.SetStateAction<string>>
  cvv: string
  setCVV: React.Dispatch<React.SetStateAction<string>>
  isSubmitting: boolean
  submitStatus: TSubmitStatus
  setSubmitStatus: React.Dispatch<React.SetStateAction<TSubmitStatus>>
  price: number
  setPrice: React.Dispatch<React.SetStateAction<number>>
  creditCardFormRef: React.RefObject<HTMLFormElement>
  handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleExpirationChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleCVVChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handlePayWithCreditCard: (event: React.FormEvent<HTMLFormElement>) => void
  handleSubmitForm: () => void
}

const CreditCardFormContext = createContext<ICreditCardFormContext>({} as ICreditCardFormContext)

export const CreditCardFormContextProvider: React.FC<ICreditCardFormContextProviderProps> = ({ children }) => {
  const fetcher = useFetcher()
  const { paymentMethod, setPaymentMethod } = usePaymentMethod()

  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [expiration, setExpiration] = useState('')
  const [cvv, setCVV] = useState('')
  const [price, setPrice] = useState(0)
  const [submitStatus, setSubmitStatus] = useState<TSubmitStatus>(null)
  const isSubmitting = useMemo(() => fetcher.state !== 'idle', [fetcher])

  const creditCardFormRef = useRef<HTMLFormElement>(null)

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value)
    },
    [setName]
  )

  const handleNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNumber(event.target.value)
    },
    [setNumber]
  )

  const handleExpirationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setExpiration(event.target.value)
    },
    [setExpiration]
  )

  const handleCVVChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCVV(event.target.value)
    },
    [setCVV]
  )

  const handlePayWithCreditCard = useCallback(
    (event: React.FormEvent<HTMLFormElement> | HTMLFormElement) => {
      if (paymentMethod !== 'credit_card') return null

      const target = event?.currentTarget || event

      fetcher.submit(target, { method: 'post', replace: true })
    },
    [fetcher, paymentMethod]
  )

  const handleSubmitForm = useCallback(() => {
    if (paymentMethod === 'credit_card' && creditCardFormRef.current) {
      handlePayWithCreditCard(creditCardFormRef.current)
    }
  }, [paymentMethod, handlePayWithCreditCard])

  useEffect(() => {
    if (fetcher.type === 'done') {
      if (fetcher.data.errorMessage) {
        setSubmitStatus(oldSubmitStatus => (oldSubmitStatus === 'error' ? oldSubmitStatus : 'error'))
      } else {
        setSubmitStatus(oldSubmitStatus => (oldSubmitStatus === 'success' ? oldSubmitStatus : 'success'))
        setPaymentMethod('credit_card')
      }
    }
  }, [fetcher, setPaymentMethod])

  const contextValues = useMemo(
    () => ({
      fetcher,
      name,
      setName,
      number,
      setNumber,
      expiration,
      setExpiration,
      cvv,
      setCVV,
      isSubmitting,
      submitStatus,
      setSubmitStatus,
      price,
      setPrice,
      creditCardFormRef,
      handleNameChange,
      handleNumberChange,
      handleExpirationChange,
      handleCVVChange,
      handlePayWithCreditCard,
      handleSubmitForm
    }),
    [
      fetcher,
      name,
      setName,
      number,
      setNumber,
      expiration,
      setExpiration,
      cvv,
      setCVV,
      isSubmitting,
      submitStatus,
      setSubmitStatus,
      price,
      setPrice,
      creditCardFormRef,
      handleNameChange,
      handleNumberChange,
      handleExpirationChange,
      handleCVVChange,
      handlePayWithCreditCard,
      handleSubmitForm
    ]
  )

  return <CreditCardFormContext.Provider value={contextValues}>{children}</CreditCardFormContext.Provider>
}

export const useCreditCardForm = () => {
  return useContext(CreditCardFormContext)
}
